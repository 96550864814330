ditech.BarKitchen.Controller = class {
  constructor(wrapper) {
    this.wrapper = $(wrapper).find(".layout-main-section");
    this.page = wrapper.page;

    this.check_opening_entry();
  }

  fetch_opening_entry() {
    return frappe.call(
      "ditech_core.ditech_core.pos.check_bar_kitchen"
    );
  }

  check_opening_entry() {
    this.fetch_opening_entry().then((r) => {
      if (r.message.length) {
        // assuming only one opening voucher is available for the current user
        // this.toggle_components(false);
        this.prepare_app_defaults(r.message[0]);
      } else {
        // this.create_opening_voucher();
        frappe.msgprint({
          title: __('Notification'),
          indicator: 'red',
          message: __('Opening entry is not create')
      });
      }
    });
  }

  async prepare_app_defaults(data) {
    this.pos_profile = data.pos_profile;
    this.item_stock_map = {};
    this.settings = {};
    this.key = data.pos_profile + " " + data.name;

    frappe.call({
      method: "ditech_core.ditech_core.pos.get_pos_profile_data",
      args: { pos_profile: this.pos_profile },
      callback: (res) => {
        const profile = res.message;
        Object.assign(this.settings, profile);
        this.settings.customer_groups = profile.customer_groups.map(
          (group) => group.name
        );
        this.make_app();
      },
    });
  }

  make_app() {
    this.prepare_dom();
    this.prepare_components();
    this.set_pos_profile_status()
    this.bind_events()
  }

  prepare_dom() {
    this.wrapper.append(`<div class="pos-app"></div>`);

    this.$components_wrapper = this.wrapper.find(".pos-app");
  }

  prepare_components() {
    this.init_bar_kitchen();
  }
  init_bar_kitchen() {
    this.bar_kitchen = new ditech.BarKitchen.BarKitchen({
      wrapper: this.$components_wrapper,
      pos_profile: this.pos_profile,
      settings: this.settings,
      events: {},
    });
  }
  bind_events() {
    frappe.realtime.on(this.key, async (res) => {
      switch (res.type) {
        case "Refresh Kitchen":
          this.bar_kitchen.load_items_data();
          break;
        default:
          break;
      }
    });
  }
  set_pos_profile_status() {
    this.page.set_indicator(this.pos_profile, "blue");
  }
};
